$media-small: 620px;
$padding: 24px;
$max-width: 800px;

/* ARTICLE BLOG */

article {
  font-size: 1.1em;
  line-height: 1.7;
}

article p {
  margin: 1.2em;
}


article p,
article ul,
article ol,
article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  margin-left: auto;
  margin-right: auto;
}

article img, p img {
  display: block;
  margin: 1.5em auto;
  border: 2px solid #874eb94d;
  border-radius: 16px;
  height: auto;
}

blockquote, q, .blockquote, .q{
  font-size: 1.2em;
  /* font-style: italic; */
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  padding: 0 1em; /* large screen */
  margin: 0;
  opacity: 1;
}

blockquote p {
  min-width: 100%;
  padding: 1em 0;
}

.hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

i {
  font-size: 20px;
  vertical-align: sub;
  opacity: 0.4;
}

article {
  max-width: $max-width;
  margin: 0 auto;
  padding: $padding;
}

article audio {
  display: block;
  width: 99%;
  margin: 0 auto;
}

article ul ul {
  width: 100%;
}

article a {
  font-weight: 700;
  text-decoration: none;
  transition: all 0.3s;
  border-bottom: 2px solid;
}

code {
  text-wrap: balance;
  font-size: 0.9em;
}


/* SMALL SCREEN */
@media (max-width: $media-small) {

  article img, article video {
    min-width: 100vw;
    position: relative;
    left: -$padding;
    border: none;
    border-radius: 0;
  }

  blockquote, q, .blockquote, .q {
    min-width: 100vw;
    position: relative;
    /* SMALL SCREEN */
    font-size: 1.15em;
    padding: 0 .8em 0 .8em;
    left: -0.8em;
  }

  article figure, div.highlight {
    padding: 10px 20px;
    border-radius: 6px;
    margin: 2em 0;
    border: 2px solid rgb(99, 99, 99);
  }
}