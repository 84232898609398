.masonry { /* Masonry container */
    column-count: 4;
        -webkit-column-count: 4;
        -moz-column-count:4;
    column-gap: 1em;
        -webkit-column-gap: 1em;
        -moz-column-gap: 1em;
    padding: 0;
    column-gap: 1em;
        -moz-column-gap: 1em;
        -webkit-column-gap: 1em;
  }
  .masonry-item {
      display: inline-block;
      margin: 0 0 .5em;
      width: 100%;
      transition:1s ease all;
        -webkit-transition:1s ease all;
      box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
  }
  .masonry-item img{max-width:100%;}
  
  @media only screen and (max-width: 550px) {
      .masonry {
        column-count: 1;
            -moz-column-count: 1;
            -webkit-column-count: 1;
      }
  }
  
  @media only screen and (min-width: 551px) and (max-width: 900px){
      .masonry {
        column-count: 2;
          -moz-column-count: 2;
          -webkit-column-count: 2;
      }
  }
  @media only screen and (min-width: 901px) and (max-width: 1480px){
      .masonry {
        column-count: 3;
          -moz-column-count: 3;
          -webkit-column-count: 3;
      }
  }

  @media only screen and (min-width: 1481px) {
      .masonry {
        column-count: 4;
          -moz-column-count: 4;
          -webkit-column-count: 4;    
      }
  }