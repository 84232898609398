.book-3d {
    --book-thickness: 26px;
    --cover-color: rgba(0, 0, 0, 0.2);
    @media (prefers-color-scheme: dark) {
        --cover-color: rgba(255, 255, 255, 0.5);
    }
    --cover-outline: none;
    perspective: 1000px;
    max-width: 250px;
    // margin: 0 auto;
    // margin-left: -2px;
}

@media (prefers-color-scheme: dark) {
    .book-3d {
        --cover-outline: 2px solid rgb(255, 255, 255, 0.2);
    }
}

.book-3d__inner {
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(-25deg);
}

/* Book Pages */
.book-3d__inner::before {
    position: absolute;
    content: ' ';
    left: 100%;
    top: 1%;
    width: calc( var(--book-thickness) * 2 );
    height: 98%;
    transform: translate(-55%,0) rotateY( 90deg );
    background: linear-gradient( 90deg , #fff 0%, hsl(0, 0%, 94%) 5%, #fff 10%, hsl(0, 0%, 94%) 15%, #fff 20%, hsl(0, 0%, 94%) 25%, #fff 30%, hsl(0, 0%, 94%) 35%, #fff 40%, hsl(0, 0%, 94%) 45%, #fff 50%, hsl(0, 0%, 94%) 55%, #fff 60%, hsl(0, 0%, 94%) 65%, #fff 70%, hsl(0, 0%, 94%) 75%, #fff 80%, hsl(0, 0%, 94%) 85%, #fff 90%, hsl(0, 0%, 94%) 95%, #fff 100% );
}

/* Rear Cover */
.book-3d__inner::after {
content: '';
    position: absolute;
    top: 0;
    left: 1%;
    width: 100%;
    height: 100%;
    transform: translateZ( calc( var(--book-thickness) * -1 ) );
    background-color: var(--cover-color);
    border-radius: 6px;
    // box-shadow: -10px 0 50px 10px rgba(0,0,0, 0.3);
}

.book-3d__cover-wrapper {
    border: var(--cover-outline);
    border-radius: 6px;
    overflow: hidden; // Ensure the border-radius is respected
}

.book-3d__cover {
    display: block;
    width: 100%;
    height: auto;
    transform: translateZ(var(--book-thickness));
}

.book-3d__cover.smooth-shadow {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 6px;
    transform: translateZ( var(--book-thickness) );
    border: var(--cover-outline);
}