@import 'typography', 'article', 'code', 'print', 'masonry', 'book', 'carousel';

$media-xl: 1700px;
$media-xl-containers: 1440px;
$media-small: 620px;
$media-xs: 370px;

$container: 1240px;
$container-lg: 1440px;

html { scroll-behavior: smooth; }

/* COLORS */

$light-bg: #E2E7F5; // #EAE6E3
$light-bg-gradient:
radial-gradient(at 23% 84%, hsla(195,100%,79%,0.33) 0px, transparent 50%),
radial-gradient(at 49% 100%, hsla(224,92%,89%,0.38) 0px, transparent 50%),
radial-gradient(at 53% 23%, hsla(2,100%,96%,0.55) 0px, transparent 50%),
radial-gradient(at 65% 26%, hsla(123,66%,88%,0.48) 0px, transparent 50%);
$dark-bg: #050736;
// $dark-bg: #0e142f;
$dark-bg-gradient: radial-gradient(at 12% 26%, rgb(10, 27, 80) 0px, transparent 50%),
radial-gradient(at 48% 55%, hsla(202,78%,17%,1) 0px, transparent 50%),
radial-gradient(at 28% 95%, hsla(273,65%,17%,1) 0px, transparent 50%),
radial-gradient(at 42% 60%, hsla(180,70%,17%,1) 0px, transparent 50%),
radial-gradient(at 62% 19%, hsla(289,81%,17%,1) 0px, transparent 50%),
radial-gradient(at 79% 79%, hsla(233,71%,17%,1) 0px, transparent 50%);


// $light-faint: #ECF2F9;
$light-faint: #ffffff5c;
// $dark-faint: #1f1c3f;
$dark-faint: hsla(246, 100%, 85%, 0.102);

$light-headings: #17181D; // #3d3333
$dark-headings: hsl(246, 100%, 98%);

$light-body: #313645; // #4d4444
$dark-body: hsl(246, 100%, 94%);

$light-secondary: #525455; // #535353
$dark-secondary: hsl(246, 100%, 92%);

$light-tertiary: #5b759f; // #777777
$dark-tertiary: #96a8df;

$dark-border: #e1dcff25;
$light-border: hsla(232, 67%, 59%, 0.15);

$light-a: #0024ef; // #3e4497
$dark-a: #6fe7d6; 

$light-a-hover: #000000; 
$dark-a-hover: #ffffff; 

$light-selection: #82c5bc; 
$dark-selection: #038573; 

* {
  box-sizing: border-box;
}

.border-rainbow {
  /* just the color, width etc must be defined */
  border-image: linear-gradient(90deg, #dc3b3e 10%, #ff8f00 28%, #ffe066 35%, #3bdc90 50%, #3b99dc , #d535c3 90%) 100;
}

.skip-to-content-link {
  z-index: 999999999;
  background: $light-bg;
  color: $light-a;
  top: 0;
  left: 0;
  padding: 12px 24px;
  position: absolute;
  transform: translateY(-500%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

/* DEFAULT LIGHT  */

.sticky {
  position: sticky;
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

body {
  background-color: $light-bg;
  background-image: $light-bg-gradient;
}

body, nav a, .new-nav a, .main, footer a {
  color: $light-body;
}

strong,
b {
  color: $light-headings;
  
  @media (prefers-color-scheme: light) { 
    color: $light-headings;
  }
  @media (prefers-color-scheme: dark) {
   color: $dark-headings;
  }
}

blockquote, q, .blockquote, .q { 
  @media (prefers-color-scheme: light) { 
    color: $light-headings;
    border-left: 10px solid $light-faint;
    background-color: $light-faint;
  }
  @media (prefers-color-scheme: dark) {
   color: $dark-headings;
   border-left: 10px solid $dark-faint;
   background-color: $dark-faint;
  }
}

.new-nav nav, .new-nav .item {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(8px); /* fixes blur for Safari*/
  backdrop-filter: blur(8px);
}

.mobile-nav-hover:hover {  background-color: rgba(206, 208, 239, 0.35); }
.bg-nav { background-color: rgb(255, 255, 255); }

h1, h2, h3, h4, h5, h6 {
  color: $light-headings;
}

del {
  text-decoration: line-through;
  text-decoration-color: rgba(215, 54, 78, 0.65);
  text-decoration-thickness: 4px;

}

.hero-headline {
  // font-size: 7.5vw;
  font-size: 3em; // fallback if clamp is not supported?
  // font-size: calc(1em + 6.5vw); // no because there's no MAX
  font-size: clamp(2.8em, 9.7vw, 7em); // min, preferred, max
}

a {
  color: $light-a;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

a:hover {
  color: $light-a-hover;
}

::-moz-selection {
  background: $light-selection;
}

::selection {
  background: $light-selection;
}

.header {
  background-color: $light-faint;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.178);
}

svg {
  fill: $light-headings;
}

.secondary {
  color: $light-secondary;
}

.tertiary {
  color: $light-tertiary;
}

.vw-100 { width: 100vw; }

.smooth-shadow {
  box-shadow:
  0 1.5px 54.5px -71px rgba(0, 0, 0, 0.74),
  0 3.5px 46.5px -71px rgba(0, 0, 0, 1.023),
  0 6.7px 33.5px -71px rgba(0, 0, 0, 1.056),
  0 13.2px 25.5px -71px rgba(0, 0, 0, 0.969),
  0 49px 80px -71px rgba(0, 0, 0, 1);

  @media (prefers-color-scheme: dark) {
    box-shadow:
    0 1.5px 24.5px -31px rgba(255, 255, 255, 0.2),
    0 3.5px 26.5px -31px rgba(255, 255, 255, 0.3),
    0 6.7px 33.5px -31px rgba(255, 255, 255, 0.3),
    0 13.2px 45.5px -31px rgba(255, 255, 255, 0.1),
    0 29px 60px -31px rgba(255, 255, 255, 0.2);
  }
}

.outline {
  outline: 1px solid rgba(22, 19, 63, 0.1);
  outline-offset: -1px;
}

.bg-faint {
  background-color: $light-faint;
}

.more-grid img {
  width: 100%;
  height: auto;
}

.bg-red-20 { background-color: hsla(6, 100%, 61%, 0.2); }
.bg-red-30 { background-color: hsla(6, 100%, 61%, 0.3); }
.bg-red-50 { background-color: hsla(6, 100%, 61%, 0.5); }
.bg-red-70 { background-color: hsla(6, 100%, 61%, 0.7); }
.bg-red-80 { background-color: hsla(6, 100%, 61%, 0.8); }

.bg-green-20 { background-color: hsla(123, 100%, 61%, 0.2); }
.bg-green-30 { background-color: hsla(123, 100%, 61%, 0.3); }
.bg-green-50 { background-color: hsla(123, 100%, 61%, 0.5); }
.bg-green-70 { background-color: hsla(123, 100%, 61%, 0.7); }
.bg-green-80 { background-color: hsla(123, 100%, 61%, 0.8); }

/* Tachyons -NS */
@media screen and (min-width: 50em) { 
  .bg-faint-ns {
    background-color: $light-faint;
  }

  .bg-transparent-ns { background-color: transparent; }
}

.b--faint, hr {
  border-color: $light-border;
}

.hover-bg-faint:hover { background-color: $light-faint; }

.hover-rotate-left:hover {rotate: -15deg;}
.hover-rotate-right:hover {rotate: 15deg;}

.rotate--15 { -webkit-transform: rotate( -15deg ); transform: rotate( -15deg ); }

.fit-content {
  height: fit-content;
  width: fit-content;
  min-width: fit-content;
}

.w-fit-content {
  width: fit-content;
  min-width: fit-content;
}

.h-fit-content {
  min-height: fit-content;
  height: fit-content;
}

.gap1 { gap: .25rem; }
.gap2 { gap: .5rem; }
.gap3 { gap: 1rem; }
.gap4 { gap: 1.5rem; }

.min-h-150 { min-height: 150px;}

/* Mobile navbar */

#open-menu {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  bottom: 60px;

  border-radius: 1rem; // 16px
  padding: 0.5rem; // 8px
  border-width: 0.25rem; // 4px
  border-color: $light-border;

  @media screen and (min-width: 50em) { 
    bottom: unset;
    top: 60px;
  }

  nav {
    background-color: #E6E9F1; // light mode color
  }
}

#open-menu a {
  border-radius: 0.5rem; // 8px
}


#open-menu.open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

nav {
  background-color: #E6E9F1; // light mode color
}

.project-image img { 
  width: auto;
  height: auto;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: $dark-bg;
    background-image: $dark-bg-gradient;
  }

  body, nav a, .new-nav a, .main, footer a {
    color: $dark-body;
  }

  .new-nav nav, .new-nav .item {
    background-color: hsla(239, 72%, 11%, 0.9);
    // border: $dark-faint 3px solid;
  }

  .mobile-nav-hover:hover {  background-color: #ffffff2e; }
  .bg-nav { background-color: rgba(206, 208, 239, 0.15); }

  h1, h2, h3, h4, h5, h6 {
    color: $dark-headings;
  }

  a {
    /* color: #a1d8f1; */
    color: $dark-a;
  }

  a:hover {
    color: $dark-a-hover;
  }
  
  .project-image {
    background-color: hsla(233, 51%, 72%, 0.5);
  }


  ::-moz-selection {
    background: $dark-selection;
    color: white;
  }
  
  ::selection {
    background: $dark-selection;
    color: white;
  }

  .header {
    background-color: $dark-faint;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.9);
  }

  svg {
    fill: #ebeff3;
  }

  .secondary {
    color: $dark-secondary;
  }

  .tertiary {
    color: $dark-tertiary;
  }

  .bg-faint {
    background-color: $dark-faint;
  }

    /* Tachyons -NS */
  @media screen and (min-width: 50em) { 
    .bg-faint-ns {
      background-color: $dark-faint;
    }
    .bg-transparent-ns { background-color: transparent; }
  }


  .b--faint, hr {
    border-color: $dark-border;
  }

  .hover-bg-faint:hover { background-color: $dark-faint; }

  nav {
    background-color: #290E46; // dark mode color
  }

  #open-menu {
    nav {
      background-color: #290E46; // dark mode color
    }
  }

}
@media (prefers-color-scheme: light) {
  body {
    background-color: $light-bg;
    background-image: $light-bg-gradient;
  }

  body, nav a, .new-nav a, .main, footer a {
    color: $light-body;
  }

  .new-nav nav, .new-nav .item {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .mobile-nav-hover:hover {  background-color: rgba(206, 208, 239, 0.35); }
  .bg-nav { background-color: rgb(255, 255, 255); }

  h1, h2, h3, h4, h5, h6 {
    color: $light-headings;
  }

  a {
    color: $light-a;
  }
  a:hover {
    color: $light-a-hover;
  }
  
  ::-moz-selection {
    background: $light-selection;
  }
  
  ::selection {
    background: $light-selection;
  }

  .header {
    background-color: $light-faint;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.178);
  }

  svg {
    fill: $light-headings;
  }

  .language-plaintext {
    border: 1.5px solid rgb(212, 212, 212) ;
    background-color: rgb(238, 238, 238);
  }

  .secondary {
    color: $light-secondary;
  }

  .tertiary {
    color: $light-tertiary;
  }

  .bg-faint {
    background-color: $light-faint;
  }

  /* Tachyons -NS */
  @media screen and (min-width: 50em) { 
    .bg-faint-ns {
      background-color: $light-faint;
    }
    .bg-transparent-ns { background-color: transparent; }
  }

  .b--faint, hr {
    border-color: $light-border;
  }

  .hover-bg-faint:hover { background-color: $light-faint; }

  #open-menu {
    nav {
      background-color: #E6E9F1; // light mode color
    }
  }
}

body {
  /* font-family: 'Inter', Arial, sans-serif; */
  font-family: 'Space Grotesk', Arial, sans-serif;
  font-size: 18px;
  line-height: 1.4;
}

.nav {
  max-width: $container;
  padding: 16px 32px;
  margin-left: auto;
  margin-right: auto;
}

hr {
  border-width: 1px;
  border-style: solid;
  margin: 4em 0;
}

.nml {
  margin-left: -6px;
}

.container, .container-ns {
  max-width: $container;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
}

.container-lg, .container-lg-ns {
  max-width: $container-lg;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
}

.max-vh-80 {
  max-height: 80vh;
}
.max-vh-70 {
  max-height: 70vh;
}


::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
}

/* PAGE PROGRESS BAR */
.progress { 
  position: fixed; top: 0;
  z-index: 9999;
  left: 0;
  height: 4px;
  background-color: #3bdc90; 
  border-bottom: solid 4px;
  border-image: linear-gradient(90deg, #dc3b3e 10%, #ff8f00 28%, #ffe066 35%, #3bdc90 50%, #3b99dc , #d535c3 90%) 100;
}


.project-images {
  height: 400px;
}

/* GPT scroll overlays */

/* Style for the scroll arrows */
.scroll-arrow {
  position: absolute;
  top: 0;
  width: 80px;
  height: 100%;
  display: flex;
  background-color: rgba(38, 35, 164, 0.06);
  // background: linear-gradient(90deg, #19009300 0%, #1d00ae42 100%);
  cursor: pointer;
  z-index: 2;
  align-items: center;
  justify-content: center;
}

.scroll-arrow:hover {
  opacity: 1;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scroll-container {
  scrollbar-width: none; /* For Firefox */
}

@media (max-width: 800px) {
  /* Hide arrows on mobile */
  .scroll-arrow {
    display: none;
  }
}

/* HUGE SCREEN */
@media (min-width: $media-xl) {
  .container, .container-ns, .container-lg, .container-lg-ns {
    max-width: $media-xl-containers;
  }

  .hero-headline {
    font-size: clamp(2.8em, 9.7vw, 8.1em); // min, preferred, max
  }
  .nav {
    max-width: $media-xl-containers;
  }
}

/* SMALL SCREEN */
@media (max-width: $media-small) {
  body {
    font-size: 18px;
    margin-bottom: 6em;
  } 
  .project-images {
    height: 320px;
  }
  .flag-icon {
    height: 5px;
    padding: 0;
  }

  .nav {
    padding: 16px;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-ns, .container-lg-ns {
    max-width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }

  .nml {
    margin-left: -2px;
  }

  .header {
    position: fixed;
    width: 100vw;
    left: 0px;
    bottom: 0px;
    margin: 0;
    z-index: 999;
    @media (prefers-color-scheme: light) { 
      background-color: $light-bg;
    }
    @media (prefers-color-scheme: dark) {
      background-color: $dark-bg;
    }
  }

  .scroll-overlay {
    display: none;
  }

}


.lh0 { line-height: 0; }

.tag {
  padding: 8px 18px;
  border-radius: 100px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
}

.tag.tag-blue {
  @media (prefers-color-scheme: light) {
    background: #83cfff60;
    color: #003D63;
  }
  @media (prefers-color-scheme: dark) {
    background: #84C4FF59;
    color: #BEE0FF;
  }
}

.tag.tag-green {
  @media (prefers-color-scheme: light) {
    background: #0ae45333;
    color: #003712;
  }
  @media (prefers-color-scheme: dark) {
    background: #1BFF6959;
    color: #90FFB6;
  }
}

.tag.tag-yellow {
  @media (prefers-color-scheme: light) {
    background: #ffdd89c9;
    color: #5c4200;
  }
  @media (prefers-color-scheme: dark) {
    background: #FFE6314D;
    color: #FFE631;
  }
}

.tag.tag-pink {
  @media (prefers-color-scheme: light) {
    background: #ffaffb69;
    color: #760070
  }
  @media (prefers-color-scheme: dark) {
    background: #FF8AFA59;
    color: #FFBDFC;
  }
}



.book-container-gradient {
  @media (prefers-color-scheme: light) {
    background: linear-gradient(90deg, #ffffff00 0%, $light-bg 83.85%);
  }
  @media (prefers-color-scheme: dark) {
    background: linear-gradient(90deg, #00000000 0%, $dark-bg 83.85%);
  }
}

.pa35 {
  padding: 1.5rem;
}

/* VERY SMALL SCREEN */
@media (max-width: $media-xs) {
  h1 {
    font-size: 1.5em;
  }
  .dn-xs {
    display: none;
  }
}


.work-grid, .more-grid, .project-grid {  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 2rem;
  grid-auto-flow: row;
  grid-template-areas:
    ".";
}


/* Tachyons -NS */
@media screen and (min-width: 50em) { 
  .work-grid, .more-grid { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    grid-template-areas:
      ". . .";
  }

  .pa35-ns {
    padding: 1.5rem;
  }

  .gap1-ns { gap: .25rem; }
  .gap2-ns { gap: .5rem; }
  .gap3-ns { gap: 1rem; }
  .gap4-ns { gap: 1.5rem; }

}

/* Tachyons -M */
@media screen and (min-width: 50em) and (max-width: 100em) {
  .work-grid{  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
      ". .";
  }

  .project-grid { 
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      ". .";
  }

  .pa35-m {
    padding: 1.5rem;
  }
}

/* Tachyons -L */
@media screen and (min-width: 60em) {

  .pa35-l {
    padding: 1.5rem;
  }

  .project-grid { 
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      ". . .";
  }
}

.shrink { -moz-osx-font-smoothing: grayscale; -webkit-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform: translateZ( 0 ); transform: translateZ( 0 ); transition: -webkit-transform .25s ease-out; transition: transform .25s ease-out; transition: transform .25s ease-out, -webkit-transform .25s ease-out; }
.shrink:hover, .shrink:focus { -webkit-transform: scale( .95 ); transform: scale( .95 ); }

.raise { -moz-osx-font-smoothing: grayscale; -webkit-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform: translateZ( 0 ); transform: translateZ( 0 ); transition: -webkit-transform .25s ease-out; transition: transform .25s ease-out; transition: transform .25s ease-out, -webkit-transform .25s ease-out; }
.raise:hover, .raise:focus { -webkit-transform: translateY(-1rem); transform: translateY(-1rem); }