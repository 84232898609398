/* FONTS */

/* Inter - old

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('/assets/fonts/Inter-Bold.woff') format('woff'),
        url('/assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('/assets/fonts/Inter-Regular.woff') format('woff'),
        url('/assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-Italic.woff2') format('woff2'),
        url('/assets/fonts/Inter-Italic.woff') format('woff'),
        url('/assets/fonts/Inter-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-Medium.woff2') format('woff2'),
        url('/assets/fonts/Inter-Medium.woff') format('woff'),
        url('/assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Inter-SemiBold.woff') format('woff'),
        url('/assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  */
  
  /* Space Grotesk */
  
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Bold.ttf') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }  


  a {
    text-decoration: none;
    transition: all 0.3s;
    font-weight: 700;
  }
  
  strong,
  b {
    font-weight: 700;
  }
  
  i,
  em {
    font-style: italic;
  }
  
  p,
  ol,
  ul {
    margin: 1em 0;
  }
  
  li ul { margin-top: 0; margin-bottom: 0; }
  
  ul,
  ol {
    padding-left: 1.3em;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  
  ol {
    list-style: decimal;
  }
  
  li {
    margin: 0.5em 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Space Grotesk', 'Arial Narrow', Tahoma, Arial, sans-serif;
    /* font-family:'Arial Narrow', Tahoma, Arial, sans-serif;  
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-weight: 600;
    text-transform: none;
  }
  
  h1 {
    font-size: 2.3em;
    @media screen and (max-width: 420px) {
      font-size: 2em;
    }
    
    line-height: 1.1;
    margin: 0.5em 0;
    text-transform: uppercase;
  }
  
  h2 {
    font-size: 1.8em;
    line-height: 1.2;
    margin: 2em 0 0.8em 0;
    text-transform: uppercase;
  }
  
  h3 {
    font-size: 1.4em;
    line-height: 1.2;
    margin: 2em 0 0.5em 0;
    text-transform: uppercase;
  }
  
  h4 {
    font-size: 1.1em;
    margin: 1.5em 0 0.5em 0;
    text-transform: uppercase;
  }
  
  h5,
  h6 {
    font-size: 1em;
    margin: 1.5em 0 0.5em 0;
    text-transform: uppercase;
  }